








import BlockList, { loadBlockData } from '~/components/global/blocks/block-list'
import pageMixin from '~/mixins/page-mixin'
import getTower from '~/queries/pages/tower.gql'
homeSlug = '__home__'

# Resolve both explicit towers as well as other tower-like pages to get their
# Craft type and the slug Craft is expecting
parseUri = (uri = '') ->
	if matches = uri.match /^(brand)\/(.+)/
		type: matches[1]
		slug: matches[2]
	else
		type: 'tower'
		slug: uri || homeSlug

export default
	name: 'Tower'

	mixins: [ pageMixin ]

	components: { BlockList }

	# Title / SEO
	head: ->
		image = if @page.blocks[0]?.__typename == 'blocks_marquee_BlockType'
		then @page.blocks[0].primaryImage
		@buildHead { image }

	asyncData: (context) ->
		{ app: {$axios, $craft, $notFound }, route, params, payload } = context

		# Don't query if hmr related
		return $notFound() if params.tower?.match /__webpack_hmr/

		# Get data
		page = payload || await $craft.getEntry
			query: getTower
			variables: parseUri params.tower
		return $notFound() unless page

		# Hydrate some blocks with additional data so that it gets SSRed
		await loadBlockData context, page.blocks

		# Set data
		return { page }

